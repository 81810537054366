<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >Mostrando {{ start_page }} a {{ to_page }} de
                {{ total_data }} registros</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="current_page"
                :total-rows="total_data"
                :per-page="perpage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perpage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="mr-2">registros</label>
            <feather-icon
              class="cursor-pointer"
              icon="RefreshCcwIcon"
              size="20"
              @click="resetSearch"
            />
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search_input"
                class="d-inline-block mr-1"
                placeholder="Padrillo o madrilla..."
                debounce="500"
              />
              <b-button
                variant="primary"
                @click="modalAddMigration = true"
                v-if="isAdmin"
              >
                <span class="text-nowrap">Agregar migración</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <b-table
          :api-url="'/api/reproduction/get-migrations'"
          ref="refMigrationList"
          class="position-relative"
          :items="myProvider"
          :stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
          sticky-header="75vh"
          :fields="arrayColumns"
          primary-key="id"
          table-class="text-nowrap"
          responsive
          show-empty
          :busy.sync="isBusy"
          empty-filtered-text="No hay registros que coincidan con tu búsqueda."
          empty-text="No hay registros que mostrar."
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="current_page"
          :per-page="perpage"
          :filter="search_input"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Cargando...</strong>
            </div>
          </template>
          <template #cell(placa_padrillo)="data">
            <specimen-card
              :postura="{
                padrillo_alias: data.item.padrillo_alias,
                placa_padrillo: data.item.placa_padrillo,
                padrillo_thumb: data.item.padrillo_thumb,
                padrillo_id: data.item.padrillo_id,
              }"
              :index="data.index"
              isPadrillo
              @seeImage="
                seeimage(
                  1,
                  data.item.padrillo_photo,
                  data.item.placa_padrillo,
                  data.item.padrillo_alias,
                  data.item.padrillo_id
                )
              "
            ></specimen-card>
          </template>
          <template #cell(placa_madrilla)="data">
            <specimen-card
              :postura="{
                madrilla_alias: data.item.madrilla_alias,
                placa_madrilla: data.item.placa_madrilla,
                thumb: data.item.thumb,
                madrilla_id: data.item.madrilla_id,
              }"
              :index="data.index"
              isMadrilla
              @seeImage="
                seeimage(
                  1,
                  data.item.photo,
                  data.item.placa_madrilla,
                  data.item.madrilla_alias,
                  data.item.madrilla_id
                )
              "
            ></specimen-card>
          </template>
          <template #cell(date_of_birth)="data">
            {{ data.item.date_of_birth | myGlobalDayWhour }}
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_by_name }}
            {{ data.item.created_by_last_name }} <br />
            {{ data.item.created_at | myGlobalDayWhour }}
          </template>
          <template #cell(actions)="data">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer mr-1 text-info"
              size="20"
              v-if="data.item.status == 1 && isAdmin"
              @click="
                (migrationSelectedId = data.item.id),
                  (modalEditMigration = true)
              "
              :id="`edit-tooltip${data.index}`"
            />
            <b-tooltip :target="`edit-tooltip${data.index}`" placement="top">
              Editar
            </b-tooltip>
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              size="20"
              @click="deleteMigration(data.item.id)"
              v-if="data.item.status == 1 && isAdmin"
              :id="`delete-tooltip${data.index}`"
            />
            <b-tooltip :target="`delete-tooltip${data.index}`" placement="top">
              Eliminar
            </b-tooltip>
          </template>
        </b-table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Mostrando {{ start_page }} a {{ to_page }} de
              {{ total_data }} registros</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="current_page"
              :total-rows="total_data"
              :per-page="perpage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="addMigration"
      :title="'Agregar migración'"
      v-model="modalAddMigration"
      size="lg"
      footer-class="d-flex justify-content-between align-items-center p-1"
      no-close-on-backdrop
      @ok.prevent="(show = true), $refs.registerMigration.saveMigration(2)"
      @hidden="(show = false), (showSave = false), (loteId = null)"
    >
      <migration-create
        @closeModal="closeModalAddPostura"
        ref="registerMigration"
        @clearShow="(show = false), (showSave = false)"
      ></migration-create>
      <template #modal-footer="{ ok }">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          @click="$refs.registerMigration.addChicken()"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Agregar nuevo</span>
        </b-button>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="(showSave = true), $refs.registerMigration.saveMigration(1)"
            v-if="!showSave"
          >
            <span>Guardar</span>
          </b-button>
          <b-button variant="outline-primary" disabled v-else>
            <b-spinner small />
            Cargando...
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="ok()"
            v-if="!show"
          >
            <span>Confirmar</span>
          </b-button>
          <b-button variant="primary" disabled v-else>
            <b-spinner small />
            Cargando...
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="editMigration"
      :title="'Editar migración'"
      v-model="modalEditMigration"
      size="lg"
      footer-class="d-flex justify-content-between align-items-center p-1"
      no-close-on-backdrop
      @ok.prevent="(show = true), $refs.refEditMigration.updateMigration(2)"
      @hidden="(show = false), (showSave = false), (loteId = null)"
    >
      <migration-edit
        @closeModal="closeModalAddPostura"
        :migration_id="migrationSelectedId"
        ref="refEditMigration"
        @clearShow="(show = false), (showSave = false)"
      ></migration-edit>
      <template #modal-footer="{ ok }">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          @click="$refs.refEditMigration.addChicken()"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Agregar nuevo</span>
        </b-button>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            @click="
              (showSave = true), $refs.refEditMigration.updateMigration(1)
            "
            v-if="!showSave"
          >
            <span>Guardar</span>
          </b-button>
          <b-button variant="outline-primary" disabled v-else>
            <b-spinner small />
            Cargando...
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="ok()"
            v-if="!show"
          >
            <span>Confirmar</span>
          </b-button>
          <b-button variant="primary" disabled v-else>
            <b-spinner small />
            Cargando...
          </b-button>
        </div>
      </template>
    </b-modal>
    <view-image-specimens
      v-if="modal_image"
      :data_specimen="data_specimen"
      @click="closeModalimage"
    ></view-image-specimens>
  </div>
</template>
<script>
import vSelect from "vue-select";
import axios from "@/axios";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MigrationCreate from "./MigrationCreate.vue";
import MigrationEdit from "./MigrationEdit.vue";
export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      sortBy: "created_at",
      sortDesc: true,
      arrayColumns: [
        {
          key: "placa_padrillo",
          label: "Padrillo",
          class: "text-center column-table",
          sortable: true,
        },
        {
          key: "placa_madrilla",
          label: "Madrilla",
          class: "text-center",
          sortable: true,
        },
        {
          key: "date_of_birth",
          label: "Fecha de nacimiento",
          class: "text-center",
          sortable: true,
        },
        {
          key: "observation",
          label: "Observación",
          class: "text-center",
          sortable: true,
        },
        {
          key: "status_text",
          label: "Estado",
          class: "text-center",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Creado por",
          class: "text-center ",
          sortable: true,
        },
        { key: "actions", label: "Acciones", class: "text-center " },
      ],
      search_input: "",
      orderby: this.dato2 == null ? 3 : this.dato2,
      order: this.dato1 == null ? "desc" : this.dato1,
      start_page: "",
      end_page: "",
      total_data: "",
      perpage: 100,
      next_page: "",
      current_page: 1,
      to_page: "",
      dato1: "",
      dato2: "",
      loadingData: false,
      isBusy: false,
      perPageOptions: [10, 25, 50, 100],
      chickenleg: null,
      loading: false,
      createDeadsPageController: false,
      deadSpecimenDetail: false,
      selectedDeadSpecimenId: null,
      show: false,
      modalReasonController: false,
      selectedReason: {},
      modalEditMigration: false,
      showSave: false,
      migrationSelectedId: null,
      modalAddMigration: false,
      data_specimen: {
        type: "",
        url: "",
        plaque: "",
        alias: "",
        id: "",
        size: 300000000,
        last_left: false,
        last_right: false,
      },
      modal_image: false,
    };
  },
  components: {
    vSelect,
    AppCollapse,
    AppCollapseItem,
    MigrationCreate,
    MigrationEdit,
  },
  methods: {
    closeModalimage() {
      this.modal_image = false;
    },
    seeimage(type, url, plaque, alias, id) {
      this.modal_image = true;
      this.data_specimen.type = type;
      this.data_specimen.url = url;
      this.data_specimen.plaque = plaque;
      this.data_specimen.alias = alias;
      this.data_specimen.id = id;
    },
    onChangeFilter() {
      this.$refs.refMigrationList.refresh();
    },
    myProvider(ctx) {
      const promise = axios.post(
        // "/some/url?page=" + ctx.currentPage + "&size=" + ctx.perPage
        `${ctx.apiUrl}?page=${ctx.currentPage}`,
        {
          orderby: ctx.sortDesc == 1 ? "desc" : "asc",
          order: ctx.sortBy == "" ? "created_at" : ctx.sortBy,
          campo: ctx.filter,
          perpage: ctx.perPage,
        }
      );

      // Must return a promise that resolves to an array of items
      return promise.then((data) => {
        // Pluck the array of items off our axios response
        const items = data.data.data;
        this.start_page = data.data.from;
        this.current_page = data.data.current_page;
        this.perpage = data.data.per_page;
        this.next_page = this.start_page + 1;
        this.end_page = data.data.last_page;
        this.total_data = data.data.total;
        this.to_page = data.data.to;
        // Must return an array of items or an empty array if an error occurred
        return items || [];
      });
    },
    resetSearch() {
      this.search_input = "";
      this.$refs.refMigrationList.refresh();
    },
    editReason(item) {
      this.selectedReason = item;
      this.modalReasonController = true;
    },
    deleteMigration(t_migration_id) {
      this.$swal({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3490dc",
        cancelButtonColor: "#e3342f",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-outline-danger ml-1",
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/reproduction/delete-migration", {
              deleted_by: this.currentUser.user_id,
              migration_id: t_migration_id,
            })
            .then((response) => {
              this.showToast(
                "success",
                "top-right",
                "Felicidades",
                "CheckIcon",
                "La migración ha sido eliminada con éxito!"
              );
              this.$refs.refMigrationList.refresh();
            });
        }
      });
    },
    openRegisterReason() {
      this.selectedReason = null;
      this.modalReasonController = true;
    },
    showToast(variant, position, title, icon, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        }
      );
    },
    closeModalAddPostura() {
      this.modalAddMigration = false;
      this.modalEditMigration = false;
      this.$refs.refMigrationList.refresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
td.div {
  width: 100% !important;
}
@media (max-width: 960px) {
  .column-table {
    display: flex;
    flex-direction: column;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
