var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"madrilla","label":"Elige la madrilla:","label-for":"madrilla"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.arrayMadrillas,"clearable":false,"id":"madrilla","label":"alias","reduce":function (option) { return option; },"transition":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var alias = ref.alias;
var plate = ref.plate;
return [_vm._v(" "+_vm._s(alias)+" / ("+_vm._s(plate)+") ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.alias)+" / ("+_vm._s(option.plate)+") ")]}}]),model:{value:(_vm.posturaObject.placa_madrilla),callback:function ($$v) {_vm.$set(_vm.posturaObject, "placa_madrilla", $$v)},expression:"posturaObject.placa_madrilla"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"padrillo","label":"Elige el padrillo:","label-for":"padrillo"}},[_c('v-select',{attrs:{"id":"padrillo","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.arrayPadrillos,"clearable":false,"label":"alias","reduce":function (option) { return option; },"transition":""},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var alias = ref.alias;
var plate = ref.plate;
return [_vm._v(" "+_vm._s(alias)+" / ("+_vm._s(plate)+") ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.alias)+" / ("+_vm._s(option.plate)+") ")]}}]),model:{value:(_vm.posturaObject.placa_padrillo),callback:function ($$v) {_vm.$set(_vm.posturaObject, "placa_padrillo", $$v)},expression:"posturaObject.placa_padrillo"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"id":"date","label":"Fecha de nacimiento","label-for":"date"}},[_c('b-form-datepicker',{attrs:{"id":"date","date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }},model:{value:(_vm.posturaObject.date_of_birth),callback:function ($$v) {_vm.$set(_vm.posturaObject, "date_of_birth", $$v)},expression:"posturaObject.date_of_birth"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"id":"observation","label":"Observación","label-for":"observation"}},[_c('b-form-textarea',{attrs:{"id":"observation","rows":"3"},model:{value:(_vm.posturaObject.observation),callback:function ($$v) {_vm.$set(_vm.posturaObject, "observation", $$v)},expression:"posturaObject.observation"}})],1)],1)],1),_vm._l((_vm.chickens),function(chicken,index){return [_c('b-row',{key:chicken.id},[_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"Cintillo ala","label-for":"cintillo_ala"}},[_c('b-form-input',{attrs:{"id":"cintillo_ala","debounce":"100"},on:{"update":function($event){return _vm.validateCintilloAla(chicken)}},model:{value:(chicken.cintillo_ala),callback:function ($$v) {_vm.$set(chicken, "cintillo_ala", $$v)},expression:"chicken.cintillo_ala"}}),(chicken.error)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(chicken.error))]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"Género","label-for":"gender"}},[_c('v-select',{attrs:{"id":"gender","clearable":false,"label":"name","options":_vm.optionsGender,"reduce":function (option) { return option.item; }},model:{value:(chicken.gender),callback:function ($$v) {_vm.$set(chicken, "gender", $$v)},expression:"chicken.gender"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"disabled":index == 0,"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteChicken(index, chicken.migration_id)}}},[_c('span',[_vm._v("Eliminar")])])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }