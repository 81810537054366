<template>
  <div>
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group
          id="madrilla"
          label="Elige la madrilla:"
          label-for="madrilla"
        >
          <v-select
            v-model="posturaObject.id_madrilla"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="arrayMadrillas"
            :clearable="false"
            id="madrilla"
            label="alias"
            :reduce="(option) => option.id"
            disabled
            transition=""
          >
            <template #selected-option="{ alias, plate }">
              {{ alias }} / ({{ plate }})
            </template>
            <template v-slot:option="option">
              {{ option.alias }} / ({{ option.plate }})
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group
          id="padrillo"
          label="Elige el padrillo:"
          label-for="padrillo"
        >
          <v-select
            id="padrillo"
            v-model="posturaObject.id_padrillo"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="arrayPadrillos"
            :clearable="false"
            label="alias"
            disabled
            :reduce="(option) => option.id"
            transition=""
          >
            <template #selected-option="{ alias, plate }">
              {{ alias }} / ({{ plate }})
            </template>
            <template v-slot:option="option">
              {{ option.alias }} / ({{ option.plate }})
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group id="date" label="Fecha de nacimiento" label-for="date">
          <b-form-datepicker
            id="date"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            v-model="posturaObject.date_of_birth"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          id="observation"
          label="Observación"
          label-for="observation"
        >
          <b-form-textarea
            id="observation"
            rows="3"
            v-model="posturaObject.observation"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <template v-for="(chicken, index) in chickens">
      <b-row :key="chicken.id">
        <b-col cols="12" md="5">
          <b-form-group label="Cintillo ala" label-for="cintillo_ala">
            <b-form-input
              id="cintillo_ala"
              v-model="chicken.cintillo_ala"
              debounce="100"
              @update="validateCintilloAla(chicken)"
            />
            <span v-if="chicken.error" class="text-danger">{{
              chicken.error
            }}</span>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="5">
          <b-form-group label="Género" label-for="gender">
            <v-select
              id="gender"
              :clearable="false"
              v-model="chicken.gender"
              label="name"
              :options="optionsGender"
              :reduce="(option) => option.item"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-button
            :disabled="index == 0"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="deleteChicken(index, chicken.migration_id)"
          >
            <!-- <feather-icon icon="XIcon" class="mr-25" /> -->
            <span>Eliminar</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import Ripple from "vue-ripple-directive";
export default {
  props: {
    migration_id: Number,
  },
  directives: {
    Ripple,
  },
  components: {
    vSelect,
  },
  data() {
    return {
      arrayMadrillas: [],
      arrayPadrillos: [],
      posturaObject: {
        placa_madrilla: null,
        placa_padrillo: null,
        observation: null,
        postura_date: null,
        id_madrilla: null,
        id_padrillo: null,
      },
      freeCombinations: [],
      posturaId: null,
      lotePosturaId: null,
      loteId: null,
      chickens: [
        {
          cintillo_ala: null,
          gender: null,
        },
      ],
      showBig: false,
      optionsGender: [
        { item: 1, name: "MACHO" },
        { item: 2, name: "HEMBRA" },
        { item: 3, name: "SIN IDENTIFICAR" },
      ],
      toDeleteChickens: [],
    };
  },
  computed: {
    counterOfPollosNacidos() {
      let counter = 0;
      for (let i = 0; i < this.chickens.length; i++) {
        if (this.chickens[i].cintillo_ala) {
          counter = counter + 1;
        }
      }
      return counter;
    },
    isCintilloAlaRepeated() {
      let counter = 0;
      for (let i = 0; i < this.chickens.length; i++) {
        if (this.chickens[i].error) {
          counter = counter + 1;
        }
      }
      if (counter > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getMigration() {
      axios
        .post("/api/reproduction/get-migration", {
          migration_id: this.migration_id,
        })
        .then((response) => {
          this.posturaObject = response.data[0];
          this.chickens = this.posturaObject.detail;
        });
    },
    customLabel({ alias, plate }) {
      return `${alias} / (${plate})`;
    },
    getFreeCombinations() {
      return axios
        .get("/api/cintillos-pata/free-combinations")
        .then((response) => {
          this.freeCombinations = response.data;
        });
    },
    getActiveMadrillasByActivePosturas() {
      axios.get("/api/postura/active-madrillas").then((response) => {
        this.arrayMadrillas = response.data;
      });
    },
    getActivePadrillos() {
      axios.get("/api/postura/active-padrillos").then((response) => {
        this.arrayPadrillos = response.data;
      });
    },
    updateMigration(statusNumber) {
      if (!this.isCintilloAlaRepeated) {
        this.$swal({
          title: "¿Estas seguro?",
          text: "¡No podrás revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3490dc",
          cancelButtonColor: "#e3342f",
          confirmButtonText: "Confirmar",
          customClass: {
            cancelButton: "btn btn-outline-danger ml-1",
            confirmButton: "btn btn-primary",
          },
        }).then((result) => {
          if (result.value) {
            this.showBig = true;
            axios
              .post("/api/reproduction/update-migration", {
                migration_header: {
                  placa_madrilla: this.posturaObject.placa_madrilla,
                  id_madrilla: this.posturaObject.id_madrilla,
                  placa_padrillo: this.posturaObject.placa_padrillo,
                  id_padrillo: this.posturaObject.id_padrillo,
                  date_of_birth: this.posturaObject.date_of_birth,
                  observation: this.posturaObject.observation,
                  status: statusNumber,
                  created_by: this.currentUser.user_id,
                  migration_flow_id: this.migration_id,
                },
                migration_body: this.chickens,
                to_delete: this.toDeleteChickens,
              })
              .then((response) => {
                if (statusNumber == 2) {
                  if (this.freeCombinations.length > 0) {
                    this.confirmMigration();
                  } else {
                    this.$swal({
                      title: "No hay combinaciones dispnibles!",
                      text: "Para confirmar esta migración debes registrar nuevas combinaciones!",
                      icon: "error",
                      showCancelButton: true,
                      confirmButtonColor: "#3490dc",
                      cancelButtonColor: "#e3342f",
                      confirmButtonText: "OK",
                      customClass: {
                        cancelButton: "btn btn-outline-danger ml-1",
                        confirmButton: "btn btn-primary",
                      },
                      buttonsStyling: false,
                    });
                    this.showBig = false;
                  }
                } else if (statusNumber == 1) {
                  this.showBig = false;
                  this.$emit("closeModal");
                }
              });
          }
        });
      } else {
        this.$swal({
          title: "Cintillos no válidos",
          text: "Hay cintillos en el ala que existen actualmente!",
          icon: "error",
          showCancelButton: true,
          confirmButtonColor: "#3490dc",
          cancelButtonColor: "#e3342f",
          confirmButtonText: "OK",
          customClass: {
            cancelButton: "btn btn-outline-danger ml-1",
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    confirmMigration() {
      axios
        .post("/api/postura/save", {
          placa_madrilla: this.posturaObject.placa_madrilla,
          id_madrilla: this.posturaObject.id_madrilla,
          placa_padrillo: this.posturaObject.placa_padrillo,
          id_padrillo: this.posturaObject.id_padrillo,
          observation: "Migracion de data",
          postura_date: moment(this.posturaObject.date_of_birth)
            .add(-60, "days")
            .format("YYYY-MM-DD"),
          method: 1,
          created_by: this.currentUser.user_id,
        })
        .then((response) => {
          this.posturaId = response.data[0].posturaId;
          axios
            .post("/api/eggs/register-eggs", {
              register_eggs_date: moment(this.posturaObject.date_of_birth)
                .add(-45, "days")
                .format("YYYY-MM-DD"),
              posturas: [
                {
                  placa_madrilla: this.posturaObject.placa_madrilla,
                  number_eggs: this.counterOfPollosNacidos,
                  error: null,
                  postura_id: {
                    id: this.posturaId,
                  },
                },
              ],
              created_by: this.currentUser.user_id,
              register_eggs_comment: "Migracion de data",
              total_register_eggs: this.counterOfPollosNacidos,
            })
            .then((response) => {
              let obj = {
                placa_madrilla: null,
                date: moment(this.posturaObject.date_of_birth)
                  .add(-21, "days")
                  .format("YYYY-MM-DD"),
                created_by: this.currentUser.user_id,
                total_eggs: this.counterOfPollosNacidos,
                status: 9,
                fromMigration: true,
              };
              axios
                .post("/api/lote/crear", {
                  lote: obj,
                  posturas: [
                    {
                      placa_madrilla: this.posturaObject.placa_madrilla,
                      total_eggs: this.counterOfPollosNacidos,
                      remaining_eggs: 0,
                      error: null,
                      postura_id: {
                        id: this.posturaId,
                      },
                    },
                  ],
                })
                .then((res) => {
                  this.loteId = res.data[1][0].last_lote_id;
                  this.lotePosturaId = res.data[0][0].postura_lote_id;
                  axios
                    .post("/api/lote/infertiles", {
                      lote: {
                        total_eggs: 0,
                        lote_id: this.loteId,
                        created_by: this.currentUser.user_id,
                        infertile_revision_date: moment(
                          this.posturaObject.date_of_birth
                        )
                          .add(-13, "days")
                          .format("YYYY-MM-DD"),
                      },
                      posturas: [
                        {
                          lote_postura_id: this.lotePosturaId,
                          number_infertile_eggs: 0,
                        },
                      ],
                      status: 10,
                    })
                    .then((response) => {
                      axios
                        .post("/api/lote/hatcher", {
                          lote: {
                            created_by: this.currentUser.user_id,
                            lote_id: this.loteId,
                            hatcher_revision_date: moment(
                              this.posturaObject.date_of_birth
                            )
                              .add(-2, "days")
                              .format("YYYY-MM-DD"),
                          },
                        })
                        .then((response) => {
                          axios
                            .post("/api/lote/vivos", {
                              lote: {
                                created_by: this.currentUser.user_id,
                                total_alive_eggs: this.counterOfPollosNacidos,
                                total_dead_eggs: 0,
                                lote_id: this.loteId,
                                born_date: this.posturaObject.date_of_birth,
                              },
                              posturas: [
                                {
                                  lote_postura_id: this.lotePosturaId,
                                  number_alive_eggs:
                                    this.counterOfPollosNacidos,
                                  number_dead_eggs: 0,
                                  cintillo_id: {
                                    id: null,
                                  },
                                  chickenleg_id: null,
                                },
                              ],
                              status: 11,
                            })
                            .then((response) => {
                              let freeCombs = [];
                              for (
                                let k = 0;
                                k < this.freeCombinations.length;
                                k++
                              ) {
                                freeCombs.push(
                                  this.freeCombinations[k].cintillo_pata_id
                                );
                              }
                              let randomIndex = Math.floor(
                                Math.random() * (freeCombs.length - 1 + 1)
                              );
                              let cintillo_pata_id_random =
                                freeCombs[randomIndex];
                              axios
                                .post("/api/cintillo-pata/save-combinations", {
                                  combinations: [
                                    {
                                      cintillo_pata_id: cintillo_pata_id_random,
                                      id: this.posturaId,
                                    },
                                  ],
                                })
                                .then((response) => {
                                  axios
                                    .post("/api/postura/finish", {
                                      postura_id: this.posturaId,
                                      postura_comment:
                                        "Postura finalizada por migración",
                                    })
                                    .then((response) => {
                                      axios
                                        .post("/api/chick/save", {
                                          chicks: this.chickens,
                                          postura_id: this.posturaId,
                                          created_by: this.currentUser.user_id,
                                          date_of_birth:
                                            this.posturaObject.date_of_birth,
                                          lote_postura_id: this.lotePosturaId,
                                          status: 12,
                                          lote_id: this.loteId,
                                        })
                                        .then((response) => {
                                          this.$swal({
                                            title: "Reproducción migrada!",
                                            text: "¡Toda la información de esta postura ha sido migrada con éxito!",
                                            icon: "success",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3490dc",
                                            cancelButtonColor: "#e3342f",
                                            confirmButtonText: "OK",
                                            customClass: {
                                              cancelButton:
                                                "btn btn-outline-danger ml-1",
                                              confirmButton: "btn btn-primary",
                                            },
                                            buttonsStyling: false,
                                          });
                                          this.$emit("closeModal");
                                          this.showBig = false;
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    },
    addChicken() {
      let chickenObj = {
        cintillo_ala: null,
        gender: null,
        migration_id: null,
      };
      this.chickens.push(chickenObj);
    },
    deleteChicken(index, chicken_id) {
      this.chickens.splice(index, 1);
      if (chicken_id) {
        this.toDeleteChickens.push(chicken_id);
      }
    },
    validateCintilloAla(chicken) {
      this.$set(chicken, "error", null);
      axios
        .post("/api/chick/validate", {
          cintillo_ala: chicken.cintillo_ala,
        })
        .then((response) => {
          let chickens = [];
          chickens = response.data;
          if (chickens.length > 0) {
            this.$set(
              chicken,
              "error",
              "Este cintillo en el ala ya está usado"
            );
          } else {
            this.$set(chicken, "error", null);
          }
        });
    },
  },
  mounted() {
    this.posturaObject.date_of_birth = moment().format("YYYY-MM-DD");
    if (this.placa && this.madrillaController) {
      this.posturaObject.placa_madrilla = this.placa;
    } else if (this.placa && !this.madrillaController) {
      this.posturaObject.placa_padrillo = this.placa;
    }
    this.getActiveMadrillasByActivePosturas();
    this.getActivePadrillos();
    this.getFreeCombinations();
    this.getMigration();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
